<template>
  <div class="g-section main-container">
    <div class="g-wrap main-top">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-circle-plus-outline"
        @click="$router.push('course/create')"
        >添加课程</el-button
      >
      <div class="flex">
        <el-select
          v-model="queryParams.Status"
          placeholder="请选择"
          @change="changeStatus"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          class="keyword ml-8"
          placeholder="请输入关键字查询"
          v-model="queryParams.KeyWord"
          size="small"
        >
        </el-input>
        <el-button class="ml-8" @click="changeStatus">搜索</el-button>
      </div>
    </div>
    <div class="g-wrap main-bottom" v-loading="loading">
      <list
        text="暂未发布课程资源"
        :dataList="dataList"
        :totalCount="total"
        :loading="loading"
        @loadMore="loadMore"
      >
        <el-table
          class="table"
          :data="dataList"
          style="width: 100%"
          :show-header="false"
        >
          <template v-for="(item, i) in tableConfig">
            <el-table-column
              :key="i"
              :label="item.label"
              :align="item.align"
              :width="item.width"
            >
              <template slot-scope="scope">
                <div
                  :class="[
                    'row-name',
                    scope.row.Status == down ? 'opacity' : '',
                  ]"
                  v-if="item.prop == 'Name'"
                >
                  <el-image
                    class="item-img"
                    style="width: 160px; height: 90px"
                    :src="scope.row.CoverImage"
                    @click="handleClick(scope.row)"
                  >
                  </el-image>
                  <div class="row-name-detail">
                    <span
                      class="name text-line-2"
                      @click="handleClick(scope.row)"
                      >{{ scope.row.Name }}</span
                    >
                    <!-- <div class="price">
                      <span v-if="scope.row.IsCharge" class="price">¥{{ scope.row.Price }}</span>
                      <span v-else class="price">免费</span>
                    </div> -->
                  </div>
                </div>
                <div
                  :class="['status', scope.row.Status == down ? 'opacity' : '']"
                  v-else-if="item.prop == 'Status'"
                >
                  <span
                    class="dotted"
                    :style="{ background: getStatus(scope.row.Status).color }"
                  ></span>
                  <span
                    :style="{ color: getStatus(scope.row.Status).textColor }"
                    >{{ getStatus(scope.row.Status).text }}</span
                  >
                </div>
                <div
                  :class="['time', scope.row.Status == down ? 'opacity' : '']"
                  v-else-if="item.prop == 'StartTime'"
                >
                  <div>
                    {{ scope.row.CreationTime | dateTimeFilter("yyyy-MM-dd") }}
                  </div>
                  <div>
                    {{
                      scope.row.CreationTime | dateTimeFilter("HH:mm:ss")
                    }}创建
                  </div>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="操作" width="161">
            <template slot-scope="scope">
              <div :class="[]">
                <!-- <el-button
                  type="text"
                  size="mini"
                  @click="handleView(scope.row)"
                  >详情
                </el-button> -->
                <el-button
                  v-if="scope.row.Status >= 0"
                  type="text"
                  size="mini"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-dropdown>
                  <el-button
                    v-if="scope.row.Status >= 0"
                    type="text"
                    size="mini"
                    >更多</el-button
                  >
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      @click.native="handleDelete(scope.row.Id, i)"
                    >
                      <span>删除</span>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="scope.row.Status == 2"
                      @click.native="handleSetStatus(scope.row, 0)"
                    >
                      <span>下架</span>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-else
                      @click.native="handleSetStatus(scope.row, 2)"
                    >
                      <span>上架</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </list>
    </div>
  </div>
</template>

<script>
import { getPageList, deleteEntity, setStauts } from "@/api/courseseries";
import { StaffTypeEnum } from "@/utils/constant";
import List from "@/components/Common/List";
import { dateTimeFilter } from "@/filters/index";
export default {
  components: { List },
  filters: {
    dateTimeFilter,
  },
  data() {
    return {
      down: 0, //下架枚举，用来控制透明度
      loading: false,
      tableConfig: [
        { label: "课程名称", prop: "Name", align: "left" },
        { label: "当前状态", prop: "Status", width: 150, align: "center" },
        { label: "上架时间", prop: "StartTime", width: 200, align: "center" },
      ],
      dataList: [],
      queryParams: {
        OwnerId: "",
        KeyWord: "",
        Status: null,
        PageSize: 10,
        PageIndex: 1,
      },
      total: 0,
      statusOptions: [
        {
          value: null,
          label: "全部状态",
        },
        {
          value: 2,
          label: "已上架",
        },
        {
          value: 0,
          label: "未上架",
        },
      ],
    };
  },
  created() {
    //企业
    if (this.$route.path.indexOf("enterprise") > -1) {
      const unit = this.$store.getters.employee.find(
        (p) => p.StaffType == StaffTypeEnum.Enterprise
      );
      if (unit) {
        this.queryParams.OwnerId = unit.Employer.Id;
      }
    } else if (this.$route.path.indexOf("school") > -1) {
      const unit = this.$store.getters.employee.find(
        (p) => p.StaffType == StaffTypeEnum.School
      );
      if (unit) {
        this.queryParams.OwnerId = unit.Employer.Id;
      }
    }
    this.loadData();
  },

  methods: {
    async loadData() {
      try {
        this.loading = true;
        const res = await getPageList(this.queryParams);
        this.dataList = this.dataList.concat(res.Result.Data);
        this.total = res.Result.TotalCount;
      } catch (error) {
        console.log("error: ", error);
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      this.queryParams.PageIndex++;
      this.loadData();
    },
    handleShowStudent() {
      this.$router.push("/school/recruit/studentlist");
    },
    handleDelete(id, index) {
      this.$confirm("确定要删除所选课程吗?删除后将无法恢复！", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteEntity(id).then((res) => {
            if (res.IsSuccess) {
              this.dataList.splice(index, 1);
              if (this.total > 0) {
                this.total -= 1;
              }
              console.log(this.total);
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: "删除失败!",
              });
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleClick(row) {
      // this.$router.push("/training/detail/" + row.Id);
    },
    handleEdit(item) {
      this.$router.push(`course/edit?id=${item.Id}&step=1`);
    },
    handleView(item) {
      this.$message("建设中。。。");
    },
    changeStatus(e) {
      this.queryParams.PageIndex = 1;
      this.dataList = [];
      this.loadData();
    },
    async handleSetStatus(item, status) {
      try {
        const response = await setStauts(item.Id, status);
        var isSuccess = response.IsSuccess;
        var msg = response.Message;
        if (isSuccess) {
          this.$message({
            title: "成功",
            message: "操作成功",
            type: "success",
            duration: 2000,
          });
          item.Status = status;
        } else {
          this.$message({
            title: "操作失败",
            message: msg,
            type: "error",
            duration: 2000,
          });
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        this.isSaving = false;
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return {
            text: "草稿",
            color: "#CCCCCC",
            textColor: "#333333",
          };

        case 1:
          return {
            text: "审批中",
            color: "#FD951D",
            textColor: "#333333",
          };
        case 2:
          return {
            text: "已上架",
            color: "#598DF3",
            textColor: "#333333",
          };
        case 3:
          return {
            text: "已驳回",
            color: "#FF3B30",
            textColor: "#333333",
          };
        default:
          return {
            text: "未上架",
            color: "#CCCCCC",
            textColor: "#333333",
          };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  min-height: 79vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f5;
}
.g-wrap {
  background: #fff;
  // padding: 24px 16px;
}
.g-wrap.main-top {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #eeeeee;
  border-radius: 8px 8px 0px 0px;
}
.g-wrap.main-bottom {
  padding: 0 24px;
  border-radius: 0px 0px 8px 8px;
}
.main-top {
  justify-content: space-between;
  margin-top: 20px;
}
.main-bottom {
  display: flex;
  flex-direction: column;
}
.right-buttons {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.keyword {
  ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
}
.table {
  .row-name {
    display: flex;
    .item-img {
      ::v-deep .el-image__inner {
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    &-detail {
      flex: 1;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        cursor: pointer;
        &:hover {
          color: #598df3;
        }
      }
      .price {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ff7946;
        line-height: 32px;
      }
    }
  }
  .status {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .dotted {
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }
  .time {
    flex: 1;
  }

  ::v-deep .el-table__row:hover > td {
    background: #ffffff !important;
  }

  ::v-deep .el-table__row--striped:hover > td {
    background: #fafafa !important;
  }
  ::v-deep .cell {
    padding: 12px 16px;
  }
  ::v-deep .el-dropdown {
    .el-button {
      &::before {
        margin-left: 10px;
        content: "|";
        display: inline;
        color: #e8e9ea;
        margin-right: 12px;
      }
    }
  }
  .opacity {
    opacity: 0.5;
  }
}
</style>
<style lang="scss">
.table {
  .el-table__row {
    &:hover {
      box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.16),
        0px -1px 0px 0px #eeeeee;
    }
  }
}
</style>
